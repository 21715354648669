<template>
  <v-container class="pa-0 text-center fill-height" fluid>
    <v-row class="white fill-height" style="padding-top: 30%" align-content="start" justify="center" no-gutters>
      <v-col cols="12">
        <v-img alt="Success" contain height="175" src="../assets/success_check.gif" />
      </v-col>
      <v-col cols="12">
        <p class="font-weight-bold" style="color: #3d3f3c">Opening Gate</p>
      </v-col>
      <v-col cols="12" style="color: #3d3f3c">
        <p>Please proceed to exit</p>
      </v-col>
      <v-col cols="12" class="place_bottom">
        <v-progress-circular indeterminate :size="20" :width="3" color="red"></v-progress-circular>
        <span class="ml-4">Preparing receipt</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import APIHelper from "../apiHelper";
import { EventBus } from "@/lib/EventBus";
// import loggerHelper from "../loggerHelper";

export default {
  name: "SuccessCheck",
  data: () => ({
    reenterState: '',
  }),
  computed: {
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
    }),
  },
  mounted() {
    setTimeout(async () => {
      await this.getBookingState();
    }, 6000);
  },
  methods: {
    async reEnter() {
      if (this.bookingId != null) {
        try {
          var self = this;
          var bDetails = await APIHelper(
            "GET",
            "/api/v1/booking/config/" + this.bookingId + "?reEnter=1"
          );
          this.$store.commit(
            "SET_BOOKING_DETAILS",
            bDetails.data?.data ? bDetails.data.data : null
          );
          let bookingState = bDetails.data?.data?.booking?.state
          if (bookingState == 8 || bookingState == 10 || bookingState == 11) {
            this.$store.commit(
            "SET_BOOKING_DETAILS",
            bDetails.data?.data ? bDetails.data.data : null
            );
            await this.$router.replace({ path: "/checkout" });
            if(this.bookingDetails?.booking?.autoP2PDetails)
             {
              EventBus.$emit('AUTO_P2P_BID', this.bookingDetails)
             }
            EventBus.$emit('SET_FREE_BOOKING')
          } else if (bookingState == 2) {
            await this.$router.replace({ path: "/checkedin" });
            EventBus.$emit('GET_FREE_BOOKING')
          } else if (bookingState == 0) {
            this.$router.replace({ path: "/checkin" });
          } else {
            setTimeout(async () => {
              await self.reEnter();
            }, 4000);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getBookingState() {
      if (this.bookingId != null) {
        try {
          if (this.bookingDetails.booking?.type == 13 && this.checkReservationEndDate()) {
            this.reEnter();
            return;
          }
          var bDetails = await APIHelper(
            "GET",
            "/api/v1/booking/config/" + this.bookingId
          );
          let bookingState = bDetails.data?.data?.booking?.state
            ? bDetails.data.data.booking.state
            : null;

          if (bookingState == 8 || bookingState == 10 || bookingState == 11) {
            // this.postToLoggerAPI({
            //     phone: bDetails.data.data.user.contact,
            //     refId: this.bookingId,
            //     statusText: "Loaded checked out success page.",
            //   })
            this.$store.commit(
            "SET_BOOKING_DETAILS",
            bDetails.data?.data ? bDetails.data.data : null
            );
            if(this.bookingDetails?.booking?.autoP2PDetails)
          {
          await  EventBus.$emit('AUTO_P2P_BID', this.bookingDetails)
          }
            await this.$router.replace({ path: "/checkout" });
          
            EventBus.$emit('SET_FREE_BOOKING')
           
          } else if (bookingState == 2) {
            await this.$router.replace({ path: "/checkedin" });
            EventBus.$emit('GET_FREE_BOOKING')
          } else {
            setTimeout(async () => {
              await this.getBookingState();
            }, 4000);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    checkReservationEndDate() {
      return this.bookingDetails?.booking?.revEnd &&
        new Date(
          (Number(this.bookingDetails.booking.revEnd) -
            Number(this.bookingDetails.zone.timezoneoffset) - Number(this.bookingDetails.zone.timezonedstoffset)) *
          1000
        ).getTime() -
        new Date().getTime() >
        0
        ? true
        : false;
    },
  },
};
</script>
<style lang="less">
.place_bottom {
  position: absolute;
  bottom: 12%;
}
</style>
